export default {
  methods: {
    async getComplianceRequirements(tags){
      try {
        const response = await axios.post( '/api/compliance-panel/requirements', {'tags': tags});
        return response.data;
      }
      catch (e) {
        return null;
      }
    },
    async checkNotCompliedRequirements(role){
      const reqs = await this.getComplianceRequirements(role);
      return reqs.filter(f => (f.is_complied === 0 || f.is_complied === '0'));
    },
    async getSpecificNotCompliedRequirements(role, requirements){
      let notComplied = this.checkNotCompliedRequirements(role);
      return notComplied.filter(f => requirements.includes(f.id));
    },
    async getCurpForUser(uid){
      try {
        const response = await axios.get(`/api/v1/userInfo/${uid}/getCurp`);
        return response.data.curp;
      }
      catch (e) {
        return null;
      }
    },
  }
}