<template>
  <div>
    <vx-card v-if="isMounted && complianceRequirements.length > 0 && showBanner" card-background="#3b3a3d" title="Algunas acciones de la plataforma están deshabilitadas." title-color="white" >
      <p v-if="focusRequirement.panel_text" style="color: white; opacity: 0.75;line-height: 1.5rem" class="text-md">
        {{focusRequirement.panel_text}}
      </p>
      <p v-else style="color: white; opacity: 0.75;line-height: 1.5rem" class="text-md">
        RedGirasol opera como una Institución de Financiamiento Colectivo y conforme a lo que nos indica la ley,  
        deberás cumplir con una serie de requerimientos para utilizar la plataforma.  Por el momento, lo único que 
        necesitamos de tu parte son los puntos que se presentan a continuación. Si quieres saber más sobre el porqué 
        te pedimos esta información, consulta este artículo de nuestro blog dando clic <a href="https://blog.redgirasol.com/que-requerimientos-tengo-que-cumplir-para-participar-en-la-red/" class="underline bold white" target="blank">aquí.</a>
      </p>
      <div class="vx-row mt-2">
        <div :class="colClass" v-for="(requirement, index) in orderedRequirements" :key="index">
          <vs-button
            type="border"
            class="w-full border-2"
            :color="index !== 0 ? 'gray':'primary'"
            :disabled="index !== 0"
            @click.stop="onRequirementClick(requirement)"
          >{{requirement.button_text}}
          </vs-button>
        </div>
      </div>
    </vx-card>

    <!-- popup activar geolocalizacion -->
    <vs-popup
      :active.sync="geolocationPopUpActive"
      title="Atención."
      class="header-primary"
    >
      <div class="pt-1 p-4">
        <div v-if="!isLocationDenied">
          <p>
              <strong>Queremos que tu experiencia en RedGirasol sea más segura.</strong><br><br>
              Debido a esto, pedimos a todos nuestros integrantes que nos indiquen en dónde se encuentra el dispositivo con el que operarán su cuenta.
              Al momento en que des clic en “Estoy de acuerdo” aparecerá una ventana en la cual deberás dar clic en permitir o “allow” como se indica a continuación, 
              de lo contrario no podrás gozar de los beneficios de la energía limpia.
          </p>
          <img :src="geolocationImg" style="width:75%" alt="" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Ubicación">
          <vs-button class="mt-5" @click="askForLocation()">Estoy de acuerdo</vs-button>
        </div>
        <div v-else>
          <p>
              <strong>Has bloqueado el permiso a la ubicación.</strong><br><br>
              Para continuar, deberás acceder a la configuración del navegador y habilitar este permiso. 
              Si deseas saber cómo realizar esta configuración da clic <a href="https://soporte.redgirasol.com/support/solutions/articles/48001250279--c%C3%B3mo-permitir-que-mi-dispositivo-comparta-mi-ubicaci%C3%B3n-" class="underline bold" target="blank">aquí</a>. 
              Una vez que realices este proceso deberás recargar la página para aplicar los cambios y poder continuar.   
          </p>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import userComplianceHelper from "@/helpers/userComplianceHelper";
import { mapMutations } from 'vuex'
export default {
  name: "ComplianceBanner",
  props: {
    wFull: {
      default: true,
      type: Boolean
    },
    showBanner: {
      default: true,
      type: Boolean
    }
  },
  mixins: [userComplianceHelper],
  data(){
      return {
        reloadPage:true,
        isMounted:false,
        complianceRequirements: [],
        geolocationPopUpActive:false,
        geolocationImg: require('@assets/images/figs/geolocation.png'),
        isLocationDenied: false,
        locationOptions: {
          enableHighAccuracy: true,
          timeout: Infinity,
          maximumAge: 0
        }
      }
  },
  async beforeMount(){
    this.isMounted = false;
    this.setSessionLocationRegistered(false);
    await this.loadRequirements();
    if('geolocation' in navigator) {
      await this.checkLocation();
    }
    this.$emit('loadedRequirements', this.complianceRequirements.length);
    this.isMounted = true;
  },
  computed: {
    focusRequirement(){
      return this.orderedRequirements[0];
    },
    orderedRequirements(){
      return this.complianceRequirements.sort((a,b) => (a.button_order > b.button_order) ? 1: -1);
    },
    colClass(){
      if(this.wFull === true){
        return 'vx-col h-auto md:w-1/4 sm:w-1/2 w-full mt-4';
      }
      else {
        return 'vx-col h-auto xxl:w-1/3 lg:w-1/2 md:w-1/2 w-full mt-4';
      }
    }
  },
  methods: {
    ...mapMutations('auth', ['setSessionLocationRegistered']),
    onRequirementClick(requirement){
      if (requirement.code === "geolocation") {
        this.geolocationPopUpActive = true;        
      }else{
        window.location.href = requirement.compliance_link;
      }
    },
    async loadRequirements(){
      this.complianceRequirements = await this.getComplianceRequirements(this.UserTags);
    },
    async checkLocation(){
      // si la ubicacion ya fue registrada en la sesión, no se vuelve a pedir
      if (this.SessionLocationRegistered) {
        return;
      }

      const appleExpression = /Apple/i;
      const safariExpression = /Safari/i;
      if(appleExpression.test(navigator.vendor) && safariExpression.test(navigator.userAgent)){
        // Safari bypassing permissions
        if (!this.SessionLocationRegistered) {
          this.reloadPage=false;
          await this.askForLocation();
        }
      } else{
        var locationEnabled = false;
        var locationDenied = false;
        await navigator.permissions.query({name:'geolocation'}).then((result) => {
          if (result.state === 'granted') {
            locationEnabled = true;
          }else{
            if(result.state === 'denied'){
              locationDenied = true;
            }
          }
        });
        if (!locationEnabled) {
            let locationRequirement={};
            locationRequirement.button_order = 3;
            locationRequirement.button_text= "Ubicación";
            locationRequirement.code= "geolocation";
            locationRequirement.compliance_link= "/";
            locationRequirement.panel_text= null;
            this.complianceRequirements.push(locationRequirement);
        }else{
          if (!this.SessionLocationRegistered) {
            this.reloadPage=false;
            await this.askForLocation();
          }
        }
        if(locationDenied){
          this.isLocationDenied = locationDenied;
        }
      }
    },
    async askForLocation(){
      await navigator.geolocation.getCurrentPosition(this.onSuccessLocation, this.onErrorLocation, this.locationOptions);
    },
    onErrorLocation(err){
      const appleExpression = /Apple/i;
      const safariExpression = /Safari/i;
      if(appleExpression.test(navigator.vendor) && safariExpression.test(navigator.userAgent)){
        let locationRequirement={};
        locationRequirement.button_order = 3;
        locationRequirement.button_text= "Ubicación";
        locationRequirement.code= "geolocation";
        locationRequirement.compliance_link= "/";
        locationRequirement.panel_text= null;
        this.complianceRequirements.push(locationRequirement);
      }
      this.isLocationDenied = true;
      this.setSessionLocationRegistered(false);
    },
    async onSuccessLocation(pos){
      this.setSessionLocationRegistered(true);
      this.geolocationPopUpActive = false;
      var crd = pos.coords;
      await this.saveLocationOnUserLogins(crd); 
    },
    async saveLocationOnUserLogins(geolocation){
      try {
        await axios.post('/api/setUserDeviceLocation', {
          "user_id":this.UserId,
          "user_role":this.UserRole,
          "latitude":geolocation.latitude,
          "longitude":geolocation.longitude,
          "accuracy":geolocation.accuracy+" meters"
        });
        if(this.reloadPage){
          this.$router.go();
        }
        this.reloadPage=true;
      }
      catch (e) {
      }
    },
  },
}
</script>