export class BankAccountStatus {
    static UNVERIFIED = "unverified";
    static VERIFIED = "verified";
    static VERIFIED_REQUESTED = "verified_requested";
    static REJECTED = "rejected";

    static getAccountStatusName(status) {
        switch (status) {
            case BankAccountStatus.VERIFIED:
                return "Verificada";
            case BankAccountStatus.VERIFIED_REQUESTED:
                return "En verificación";
            case BankAccountStatus.REJECTED:
                return "Verificación rechazada";
            case BankAccountStatus.UNVERIFIED:
            default:
                return "Pendiente de completar";
        }
    }
}