<template>
    <section>
        <div class="container-fluid">
            <div class="col-md-12">
                <div class="alt-2"></div>
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <h1 style="padding-top: 4pt;text-align: center;">FORMATO DE
                            AUTORIZACIÓN DE CARGO A CUENTA</h1>
                        <br>
                        <h2 style="text-align: right;">Monterrey, Nuevo León, a {{ midDateFormat(todayDate) }} </h2>
                        <br>
                        <p style="padding-left: 9pt;text-indent: 0pt;text-align: justify;">Instruyó y autorizo que, con base
                            en la información que se indica en esta comunicación, se realicen cargos periódicos en mi cuenta
                            conforme a lo siguiente:</p>
                        <ol id="l1">
                            <li data-list-text="1.">
                                <p style="padding-left: 23pt;text-indent: -14pt;text-align: justify;">Nombre del proveedor
                                    del bien, servicio o crédito o préstamo, según corresponda, que pretende pagarse por
                                    medio de la presente domiciliación: <b>RED GIRASOL S.A.P.I. de C.V., INSTITUCIÓN DE
                                        FINANCIAMIENTO COLECTIVO</b>.</p>
                                <br>
                            </li>
                            <li data-list-text="2.">
                                <p style="padding-left: 23pt;text-indent: -14pt;text-align: justify;">Bien, servicio o
                                    crédito o préstamo, a pagar: <b>{{ loanType }}</b>. En su caso, el número de
                                    identificación
                                    generado por el proveedor (dato no obligatorio): <b>{{ roleId }}</b>.</p>
                                <br>
                            </li>
                            <li data-list-text="3.">
                                <p style="padding-left: 23pt;text-indent: -14pt;text-align: justify;">Tratándose de los
                                    pagos del crédito o préstamo objeto de esta Domiciliación, indicar a continuación si
                                    este es designado como un Crédito Asociado a la Nómina respecto del cual, de conformidad
                                    con las disposiciones emitidas por el Banco de México en la Circular 3/2012 o aquellas
                                    otras emitidas posteriormente, el banco que lleva la cuenta aquí referida deba realizar
                                    los cargos respectivos en el lugar del orden de prelación que deba seguirse con respecto
                                    a los demás cargos solicitados a esa misma cuenta:</p>
                                <br>
                                <p style="padding-left: 23pt;text-indent: 0pt;text-align: left;">SÍ ____
                                    NO __<b>X</b>__</p>
                                <br>
                            </li>
                            <li data-list-text="4.">
                                <p style="padding-left: 23pt;text-indent: -18pt;text-align: left;">Periodicidad del pago
                                    (Facturación) (Ejemplo: semanal, quincenal, mensual, bimestral, semestral, anual, etc.):
                                </p>
                                <h2 v-if="userRole == 'investor'" style="padding-left: 23pt;text-indent: 0pt;text-align: left;"> Mensual </h2>
                                <h2 v-else style="padding-left: 23pt;text-indent: 0pt;text-align: left;">{{ user.lower_periodicity }}</h2>
                                <br>
                            </li>
                            <li data-list-text="5.">
                                <p style="padding-left: 23pt;text-indent: -14pt;text-align: left;">Nombre del banco que
                                    lleva la cuenta de depósito a la vista o de ahorro en la que se realizará el cargo:</p>
                                <h2 style="padding-left: 23pt;text-indent: 0pt;text-align: left;">{{ bankAccount.bank_name }}</h2>
                                <br>
                            </li>
                            <li data-list-text="6.">
                                <p style="padding-left: 23pt;text-indent: -14pt;text-align: left;">Cualquiera de los datos
                                    de identificación de la cuenta, siguientes:.</p>
                                <p class="s1" style="padding-left: 23pt;text-indent: 0pt;text-align: left;"><span
                                        class="p">Número de tarjeta de débito (16 dígitos): N/A;</span>
                                </p>
                                <p style="padding-left: 23pt;text-indent: 0pt;text-align: left;">Clave Bancaria
                                    Estandarizada (“CLABE”) de la Cuenta (18 dígitos): <b>{{ bankAccount.clabe }}</b>, o <br>
                                    Número de teléfono móvil asociado a la Cuenta: <span
                                        class="s1">&nbsp;</span><b>N/A</b><span class="s1">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>.</p>
                                <br>
                            </li>
                            <li data-list-text="7.">
                                <p style="padding-left: 23pt;text-indent: -14pt;text-align: left;">Monto máximo fijo del
                                    cargo autorizado por el periodo de facturación: <b>$50,000 MXN</b><span class="s1">
                                    </span>.</p>
                                <p style="padding-left: 23pt;text-indent: 0pt;text-align: justify;">En lugar del monto
                                    máximo fijo, si el crédito indicado en esta comunicación es de un crédito revolvente
                                    asociado a una tarjeta de crédito que no sea designado en esta misma solicitud como un
                                    Crédito Asociado a la Nómina, el titular de la cuenta podrá optar por autorizar alguna
                                    de las opciones de cargo siguientes (marcar con una X la opción que, en su caso,
                                    corresponda):</p>
                                <p style="padding-left: 23pt;text-indent: 0pt;line-height: 21pt;text-align: justify;">El
                                    importe del pago mínimo del periodo: <span class="s2">□</span></p>
                                <p style="padding-left: 23pt;text-indent: 0pt;text-align: justify;">El saldo total para no
                                    generar intereses en el periodo: <span class="s2">□</span>, o</p>
                                <p style="padding-left: 23pt;text-indent: 0pt;text-align: left;">Un monto fijo: (en este
                                    último caso, especificar el monto: $<span class="s1"> &nbsp;&nbsp;&nbsp;&nbsp; </span>).
                                </p>
                                <br>
                            </li>
                            <li data-list-text="8.">
                                <p style="padding-left: 23pt;text-indent: -14pt;text-align: justify;">Como excepción a lo
                                    anterior, si el crédito indicado en esta comunicación es un crédito revolvente designado
                                    como Crédito Asociado a la Nómina, indicar a continuación si el cargo mensual deberá
                                    hacerse por el límite máximo del 10% del promedio de los abonos en la cuenta indicada en
                                    esta solicitud por las cantidades correspondientes a las prestaciones laborales del
                                    cuentahabiente, calculado conforme a las disposiciones emitidas por el Banco de México
                                    o, en lugar de dicho límite, un porcentaje inferior (marcar con una X la opción que, en
                                    su caso, corresponda):</p>
                                <p style="padding-top: 3pt;padding-left: 23pt;text-indent: 0pt;text-align: left;">Límite
                                    máximo del 10%<span class="s2">□</span></p>
                                <p style="padding-left: 23pt;text-indent: 0pt;text-align: left;">Límite menor del 10% <span
                                        class="s2">□ </span>El cual será del <span
                                        class="s1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>(indicar porcentaje)
                                </p>
                            </li>
                            <li data-list-text="9.">
                                <p class="s3"
                                    style="padding-top: 12pt;padding-left: 23pt;text-indent: -14pt;text-align: left;">Esta
                                    instrucción y autorización de cargo a mi cuenta se mantendrá vigente por plazo
                                    indeterminado <b>X</b>.</p>
                            </li>
                        </ol>
                        <br>
                        <p style="padding-left: 23pt;text-indent: 0pt;text-align: left;">En lugar de lo anterior, esta
                            instrucción y autorización de cargo a mi cuenta vence en la siguiente fecha:</p>
                        <p class="s5" style="padding-left: 23pt;text-indent: 0pt;text-align: left;">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="s2">□</span><span class="p">.</span></p>
                        <p style="padding-top: 12pt;padding-left: 9pt;text-indent: 0pt;text-align: justify;">Estoy enterado
                            de que en cualquier momento podré solicitar la cancelación de la presente domiciliación sin
                            costo a mi cargo.</p>
                        <br>
                        <p style="padding-left: 9pt;text-indent: 0pt;text-align: justify;">Asimismo, estoy enterado que, en
                            caso de que esta Domiciliación sea para el pago de un Crédito Asociado a la Nómina, la
                            institución que lleva mi cuenta de depósito inmovilizará los recursos provenientes de
                            prestaciones laborales que se abonen a ella, en la fecha de abono previa más próxima a aquella
                            en que deba realizarse el pago de dicho Crédito Asociado a la Nómina, por el monto
                            correspondiente al pago respectivo. Por lo anterior, reconozco que no podré hacer uso de los
                            recursos abonados a mi cuenta por el monto correspondiente desde la fecha mencionada.*</p>
                        <br>
                        <p class="s6" style="padding-left: 9pt;text-indent: 0pt;text-align: justify;">*La Institución no
                            estará obligada a incluir en el presente Anexo este párrafo, en aquellos casos en que la fecha
                            de abono de los recursos provenientes de prestaciones laborales sea la misma fecha en la que se
                            deba realizar el pago correspondiente.</p>
                        <br><br>
                        <p class="s1" style="text-align: center;">
                            <span class="h2">{{ user.complete_name }}</span>
                            <br>
                            <span class="s7"> </span><span class="h2">(NOMBRE O RAZÓN SOCIAL DEL TITULAR DE LA
                                CUENTA)</span>
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import dateHelper from "@mixins/dateHelper";
export default {
    name: "LatestDirectBillingAcceptanceContract",
    props: ["userRole", "bankAccount"],
    mixins: [dateHelper],
    data() {
        return {
            todayDate: new Date(),
        }
    },
    computed: {
        loanType() {
            if (this.userRole == "investor") {
                return "Inversión RedGirasol";
            }
            else {
                return "Préstamo RedGirasol";
            }
        },
        roleId() {
            if (this.userRole == "investor") {
                return "IID: " + this.InvestorId;
            }
            else {
                return "CID: " + this.ApplicantId;
            }
        },
    }
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

h1 {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

.h3 {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 9pt;
}

.h2,
h2 {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
}

.p,
p {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
    margin: 0pt;
}

.s1 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 10pt;
}

.s2 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 18pt;
}

.s3 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.s5 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 18pt;
}

.s6 {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 8pt;
}

.s7 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

li {
    display: block;
}

#l1 {
    padding-left: 0pt;
    counter-reset: c1 1;
}

#l1>li>*:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal)". ";
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

#l1>li:first-child>*:first-child:before {
    counter-increment: c1 0;
}
</style>