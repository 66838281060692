<template>
    <div id="bankaccounts-clabe">
        <div class="vx-row">
            <div class="vx-col w-full">
                <h4>Registra una cuenta</h4>
                <p>Ingresa tu cuenta CLABE</p>
                <vs-divider></vs-divider>
            </div>
            <div class="vx-col sm:w-full mb-6">
                <div class="document-detail">
                    <p class="bold black text-lg mb-2">Antes de compartirnos la cuenta:</p>
                    <ul class="check-ul">
                        <li class="black">Debe estar <span class="bold">a tu nombre</span>.</li>
                        <li class="black">RedGirasol depositará <span class="bold">un centavo</span> para validar la
                            <span class="bold">autenticidad, propiedad y recepción de transferencias</span> de tu
                            cuenta.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="vx-col sm:w-full">
                <label class="bold">CLABE</label>
                <div class="flex justify-left items-center">
                    <vs-input @input="(val) => (clabe = clabe.toUpperCase())" class="w-full" name="clabe"
                        v-validate="requiredRules" v-model.trim="clabe" :danger="hasError('clabe')"
                        :danger-text="errorText('clabe')" :success="isSuccess('clabe')" icon-pack="feather"
                        :placeholder="requiredPlaceholder" />
                    <label v-if="bankAccountRegistered" class="bold ml-5 w-full">{{
                        bankAccountRegistered.bank.short_name }}</label>
                </div>
                <vs-alert v-if="errorMssg" icon-pack="feather" style="height:auto" icon="icon-alert-triangle"
                    color="danger">
                    <span>{{ errorMssg }}</span>
                </vs-alert>
            </div>
        </div>
        <div v-if="bankAccountRegistered"
              class="flex justify-left items-center">
              <feather-icon icon="AlertTriangleIcon" svgClasses="w-6 h-6 text-dark" />
              <b class="ml-2">La verificación está en proceso, te notificaremos por correo cuando se realice y encontrarás la nueva cuenta en el listado de tus cuentas.</b>
            </div>
        <div class="vx-row mt-4">
            <div class="vx-col w-full">
                <div v-if="showSaveBtn" class="flex justify-start flex-wrap small-flex">
                    <vs-button size="sm" color="black" type="border" @click.stop="goBack()">Atrás</vs-button>
                    <vs-button :disabled="clabe == null" size="sm" class="ml-3" color="black"
                        @click.stop="saveDataForm()">Registrar</vs-button>
                </div>
                <vs-button v-else size="sm" color="black"
                @click.stop="goAccept()">Aceptar</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import investorInfoHelper from "@/helpers/investorInfoHelper";

export default {
    name: "NewUserClabe",
    // props: ["bankAccounts"],
    mixins: [investorInfoHelper],
    data: () => ({
        isMounted: false,
        document: null,
        requiredRules: "required|digits:18",
        errorMssg: null,
        colClass: "vx-col w-full",
        requiredPlaceholder: "(Requerido)",
        optionalPlaceholder: "(Opcional)",
        clabe: null,
        bankAccountRegistered: null,
        showSaveBtn:true
    }),
    // created() {

    //     if(this.bankAccountStatus == 'rejected') {
    //         this.errorMssg = "Tu cuenta ha sido rechazada. Por favor, ingresa una cuenta válida.";
    //     }

    //     this.isMounted = true;
    // },
    computed: {
        // bank_account() {
        //     return this.bankAccounts.length > 0 ? this.bankAccounts[0] : null;
        // },
        // bankAccountStatus() {
        //     return this.bank_account.verified;
        // }
    },
    methods: {
        async saveDataForm() {
            this.errorMssg = null;
            if (!(await this.validateForm())) {
                return;
            }
            this.showLoading(true, "Guardando información...");
            try {
                let payload = {
                    clabe: this.clabe,
                    check_direct_billing: true
                };
                const res = await axios.post(
                    `/api/loan-onboarding/post/${this.UserId}/update-bankaccount`,
                    payload
                );

                if (res.status == 200) {
                    this.bankAccountRegistered = res.data.bank_account;
                } else {
                    this.errorMssg = res.data;
                }
                this.showLoading(false);

                if (this.bankAccountRegistered) {
                    this.showSaveBtn=false;
                }
            } catch (e) {
                this.showLoading(false);
                console.log(e);
                this.errorMssg = e;
            }
        },
        async validateForm() {
            this.errorMssg = null;
            return this.$validator.validateAll();
        },
        goBack() {
            this.$emit("on-back");
        },
        goAccept() {
            this.$emit("on-saved");
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
    },
}
</script>